import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { ghost } from "@src/utils/ghost"
import withStyles from "@material-ui/core/styles/withStyles"
import { useTranslation } from "react-i18next"
import { Grid, Hidden, Box, TextField } from "@material-ui/core"
import { window } from "browser-monads"

import Text from "@src/components/Text"
import Button from "@src/components/Button"
import Progress from "@src/components/Progress"
import { detectLocale, detectAlpha2Country } from "@src/utils/country"

import validation from "@src/utils/validation"

import { GTMEvent } from "../utils/gtm"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

export const styles = theme => ({
  subscribeForm: {},
  disclamer: {
    padding: "4px 8px 4px 8px",
    borderRadius: 4.3,
    border: "solid 1px #9e78aa",
    maxHeight: 120,
    overflowY: "scroll",
    letterSpacing: -0.17,
    textAlign: "justify",
    color: "rgba(255, 255, 255, 0.41)",
    "& a": {
      textDecoration: "underline",
      borderBottom: "none",
      width: "max-content",
      width: "fit-content",
      fontFamily: "Spezia",
      fontSize: "inherit",
      fontWeight: "inherit",
      color: "inherit",
      "&::visited, &::hover, &::link": {
        color: "inherit",
        textDecoration: "underline",
      },
    },
  },
  resultWrapper: {
    maxWidth: "650px",
    marginLeft: "auto",
    marginRight: "auto",
    height: 60,
    color: "#fff",
    borderRadius: "4.3px",
    border: "solid 1px #9e78aa",
    display: "table",
    width: "100%",
  },
  result: {
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    width: "100%",
    color: "white",
  },
  subscribeButton: {
    height: 60,
    width: "100%",
    ...theme.fonts.faces.bodyM,
    ...theme.fonts.weights.medium,
  },
  buttonBelow: {
    width: "fit-content !important",
    margin: theme.spacing(4, 0, 2, 0),
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  emailField: {
    width: "100%",
  },
  input: {
    ...theme.fonts.faces.titleL,
    "&::placeholder": {
      color: "white",
    },
  },
  outlinedInput: {
    color: "white",
    "&$focused $notchedOutline": {
      borderColor: `white !important`,
    },
    "&$error $notchedOutline": {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  focused: {},
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  error: {},
})

const SubscriptionStatus = {
  UNSUBSCRIBED: "unsubscribed",
  ERROR: "error",
  SUCCESS: "success",
}

const subscribe = (email, audience, flags, productCode) => {
  return new Promise(resolve => {
    var data = {
      email: email,
      site: `FH${detectAlpha2Country().toUpperCase()}`,
      locale: detectLocale(),
      apikey: process.env.GATSBY_GPROXY_NEWSLETTER_URL,
      subscriptions: {
        marketing: true,
        ...flags,
      },
      audience: {
        group: audience,
        segment: productCode,
      },
      callback: function callback(result) {
        if (result?.errorCode === 0) {
          resolve(SubscriptionStatus.SUCCESS)
        } else {
          resolve(SubscriptionStatus.ERROR)
        }
      },
    }
    window.gproxy.subscriptions.upsert(data)
  })
}

const SubscribeForm = React.forwardRef(function SubscribeForm(props, ref) {
  const {
    classes,
    audience,
    flags,
    textFieldProps = {
      variant: "outlined",
    },
    labels,
    onResult = () => {},
    buttonBelow = false,
    productCode,
    onParent,
    others,
  } = props

  const { newsletter } = useContext(GlobalStateContext)

  const { i18n, t } = useTranslation()
  const [status, setStatus] = useState(SubscriptionStatus.UNSUBSCRIBED)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")

  const _labels = {
    ...t("prelaunch.subscribe", { returnObjects: true }),
    ...labels,
  }

  const validate = value => {
    var valid = true
    if (validation.validateEmail(value)) {
      setEmailError("")
    } else {
      valid = false
      if (value) {
        setEmailError(_labels.invalidEmail)
      } else {
        setEmailError(_labels.requiredField)
      }
    }
    return valid
  }

  const updateEmail = e => {
    const newEmail = e.target.value
    setEmail(newEmail)
    validate(newEmail)
    setStatus(SubscriptionStatus.UNSUBSCRIBED)
  }

  const handleClick = () => {
    GTMEvent(others.repo, "notify me", "try")

    if (validate(email)) {
      setIsLoading(true)
      subscribe(email, audience, flags, productCode).then(res => {
        setStatus(res)
        setIsLoading(false)
        onResult(res)
        onParent(res)
        GTMEvent(others.repo, "notify me", res === "success" ? "success" : "ko")
      })
    }
  }

  const handleEnterKey = e => {
    if ((e.key === "Enter" || e.charCode === 13) && !emailError) {
      handleClick()
    }
  }

  const ConfirmButton = (
    <Button
      className={clsx(classes.subscribeButton, {
        [classes.buttonBelow]: buttonBelow,
      })}
      onClick={handleClick}
      endIcon="alert"
    >
      {_labels.button}
    </Button>
  )

  return isLoading ? (
    <Progress></Progress>
  ) : (
    <>
      {(status === SubscriptionStatus.UNSUBSCRIBED ||
        status === SubscriptionStatus.ERROR) && (
        <Grid
          className={classes.subscribeForm}
          spacing={2}
          alignContent="center"
          container
        >
          <Grid xs={12} md={buttonBelow ? 12 : 8} item>
            <TextField
              {...textFieldProps}
              className={classes.emailField}
              placeholder={t("notifyme.placeholder")}
              error={!!emailError || status === SubscriptionStatus.ERROR}
              helperText={
                emailError ||
                (status === SubscriptionStatus.ERROR ? _labels.error : "")
              }
              InputProps={{
                classes: {
                  input: classes.input,
                  root: classes.outlinedInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                  error: classes.error,
                },
              }}
              onChange={updateEmail}
              onKeyPress={handleEnterKey}
            />
          </Grid>
          {!buttonBelow && (
            <Hidden smDown>
              <Grid xs={4} item>
                {ConfirmButton}
              </Grid>
            </Hidden>
          )}
          {/* <Grid xs={12} item>
            <Text
              className={classes.disclamer}
              variant="bodyXXS"
              color="textTertiary"
            >
              {newsletter && newsletter.text}
            </Text>
          </Grid> */}
          {buttonBelow && (
            <Hidden smDown>
              <Grid xs={12} item>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {ConfirmButton}
                </Box>
              </Grid>
            </Hidden>
          )}
          <Hidden mdUp>
            <Grid xs={12} item>
              {ConfirmButton}
            </Grid>
          </Hidden>
        </Grid>
      )}
      {status === SubscriptionStatus.SUCCESS && (
        <Box className={classes.resultWrapper}>
          <Text className={classes.result} variant="titleXXL" component="div">
            {_labels.success}
          </Text>
        </Box>
      )}
    </>
  )
})

SubscribeForm.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The audience
   */
  audience: PropTypes.string,
  /**
   * flags to set
   */
  flags: PropTypes.object,

  /**
   * button under disclamer
   */
  buttonBelow: PropTypes.bool,
}

export default withStyles(styles, { name: "FHSubscribeForm" })(SubscribeForm)
